*
{
    margin: 0;
    padding: 0;
}

html
{
	background: #261a2c;
}
/*body
{
    overflow: hidden;
}*/

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.section
{
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 5vmin;
    padding-left: 2%;
    padding-right: 2%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}